.custom-whskr-spin-icon g {
  animation: fill-in 1s ease-in alternate infinite;
  fill-opacity: 0;
  fill: #f5f5f5;
}


.custom-whskr-spin-icon path {
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
  stroke-opacity: 0;
  stroke: #e0e0e0;
  animation: draw 1s ease-in alternate infinite;
}

.custom-whskr-spin .ant-spin-dot {
  font-size: 15em!important;
  margin-top:0.5em !important;
}
@keyframes draw {
  from {
    stroke-dashoffset: 500;
    stroke-opacity: 1;
    stroke: #e0e0e0;
  }
  to {
    stroke-dashoffset: 0;
    stroke-opacity: 0;
    stroke: #008489;
  }
}

@keyframes fill-in {
    from {
    fill-opacity: 0;
      fill: #f5f5f5;
  }
  to {
    fill-opacity: 1;
    fill:  #008489;
  }
}
.loadingText {
  font-size: 30px;
  color: #252525;
  margin-top:100px;
}


