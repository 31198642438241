@import "../../customstyle.scss";
.ant-radio-button-wrapper{
  background: transparent;
}

.history-radio-group.ant-radio-group >label {
    width: 150px;
    text-align: center;
}


#patient-history-main-table {
    .editIcon, .trashIcon , .viewIcon {
      fill: $primaryColor;
      &:hover {
        fill: $altColor;
      }
    }
  }