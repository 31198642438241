@import "./../../../customstyle.scss";

.any-create-modal {
    width: fit-content !important;
    .ant-modal-content {
        width: 400px;
    }
}

.any-create-button {
    cursor: pointer;
    .ant-card-bordered {
        border-radius: 12px;
    }
    .calendarPlusIcon, .taskPlusIcon, .userPlusIcon, .blockOutIcon {
        fill: $primaryColor;
    }
    .any-create-button-text {
        font-size: 14px;
        font-weight: $fontWeightMedium;
        color: $primaryColor;
    }
    &:hover{
        .ant-card-bordered {
            border-color:$altColor!important ;
        }
        .ant-col {
            color:$altColor
        }
        .calendarPlusIcon, .taskPlusIcon, .userPlusIcon, .blockOutIcon, .newInvoiceIcon {
            fill: $altColor !important;
        }
        .any-create-button-text {
            color: $altColor;
        }


    }
}