@import "../../customstyle.scss";

.client-profile {

  .client-profile-info {
    .page-header {
      background-color: $altBackgroundColor;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding: 32px 24px;
      border-bottom: 1px solid $borderColor;
    }

    .edit-profile {
      margin-top: 20px;

      span {
        padding: 0 50px 0 50px;
      }
    }
    .editProfileContainer {
      display: flex;
      justify-content: center;
    }

    .custom-image .behaviorAlertCont {
      position: absolute;
      left: 20px;
    }

    .text-email-patient {
      color: $primaryText;
      color: #012729;
      position: absolute;
      top: 0px;
      left: 330px;
      width: 90px;

      .profileSendAction {
         &:hover {
           cursor: pointer;
            svg {
            fill: $altColor;
            }
          .text-client, .email-client {
            color: $altColor;
            }
          .ant-btn:hover {
            background: transparent !important;
            }
          }
        }

      svg {
        width: 20px;
        height: 20px
      }

      .email-client, .text-client-icon, .email-client-icon, .client-profile-icon {
        color: $primaryText;
      }

      .text-client, .email-client, .client-profile {
        margin-bottom: 12px;
        color: $primaryText;
        margin-top: -7px;
      }
    }

    .patient-information {
      .client-related-config, .client-primary-config, .patient-profile-config {
        padding: 24px;
      }
      .client-secondary-config {
        padding: 0px 24px 24px 24px;
      }

      .behaviorAlertIcon {
        fill: $dangerColor;
        height: 18px;
        padding-right:6px;
        vertical-align: middle;
        padding-bottom:2px;
      }

      .client-secondary-config, .patient-profile-config {
        border-bottom: 1px solid $borderColor;
      }

      .title {
        margin-top: 12px;
      }

      .patient-more-info, .client-more-info {
        margin-bottom: 12px;

        .patient-details, .client-details {
          margin-top: 20px;

          .behavior-alerts .anticon-warning {
            color: $dangerColor;
            font-size: 16px;
            margin-right: 5px;
          }

          .email-color {
            color: $altColor;
          }

          .text-color {
            color: $primaryText;
          }

          .dangerColor {
            color: $dangerColor;
          }
        }
      }
    }
  }

  .client-profile-details-tabs {

    .ant-tabs-nav {
        padding: 12px 24px 0px 24px;
    }

    .patient-profile-tabs {
      //padding: 34px;
    }

    .ant-tabs-tab {
      padding: 12px 20px 12px 20px;
    }

    .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs-content-holder {
      overflow-y: scroll;
    }

  }

  .client-profile-widget {
    border-radius: 16px;
    background: white;
   
  }
}


.relatedPatientsContainer {
  width:100%;
.relatedPatientsCard {
  padding:12px;
  background: $secondaryBackgroundColor;
  border-radius: 16px;
  margin-bottom: 16px;
  &:hover {
    background: darken($secondaryBackgroundColor, 2%)
  }
}
}