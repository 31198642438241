@import "../../../customstyle.scss";


.staff-profile {
  .delete-profile-btn {
    border: none;
    background-color: #FF4C4F;
    color:white;
  }
  .ant-drawer-header {
    border-bottom: none;
  }

  .ant-drawer-footer {
    border-top: none;

  }
}