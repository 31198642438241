@import './../../customstyle.scss';

.estimate-drawer {
    .ant-drawer-header {
      border-bottom: none;
    }

    .custom-image .circle-border {
      box-shadow: 0 0 0 3px $primaryColor;
    }
}

.estimate-action-strike {
text-decoration: line-through;
}

.patient-custom-card{
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 5%);
  margin-bottom: 16px;
  
  .anticon-right {
   font-size: 18px;
   color:$secondaryText;
  }

  .custom-image .circle-border {
    border: 3px solid $primaryColor;
  }
  
  .patient-card-child-name {
    font-size: 16px;
    font-weight: $fontWeightMedium;
  }
  .patient-card-child-breed {
    font-size: 13px;
  }

  &:hover {
    border-color: $altColor !important;
    .patient-card-child-name, .patient-card-child-breed {
      color: $altColor;
    }
  }
}


.patient-estimate-modal {
  .ant-modal-content {
    width: 485px;
  }
  .ant-modal-body {
    padding-top: 8px;
    padding-bottom: 24px;
  }
  .patient-custom-card{
    border-radius: 6px;
  }

  .estimateModalPrice {
    font-size: 16px;
    font-weight: $fontWeightSemi;
  }

  .ant-tag {
    margin-right: 0px;
  }
  .patient-custom-card .custom-image .circle-border {
    border: none;
  }
  .custom-image .circle-border {
    margin-bottom: 0px;
    box-shadow: 0 0 0 3px $primaryColor;
}
}

.estimateTotalCard {
  background: $secondaryBackgroundColor;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  .estimateTotalText {
  font-size: 24px;
}
}

.estimateSummaryRow {
  display:flex;
}

.estimateSearchCode {
  font-size: 12px;
  color: $secondaryText;
}


#estimate-detail-table.ant-table-empty{
  background-color: $secondaryBackgroundColor;
  pointer-events: none;
  tbody .ant-table-cell {
    padding: 5em !important;

  }
}

#estimate-detail-table {
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    padding: 12px 16px;
  }
  .is-external-lab {
    background-color:#FEF6ED;
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: red !important;
    }
    .external-lab-column{
      border-left:#F59A23 solid 0.3em ;
    }
    .ant-table-cell-fix-right {
        background-color:inherit;
    }
  }
}

.ant-table-tbody > tr.ant-table-row:hover.is-external-lab > td {
  background: #ffeacc !important;
}



.modalDescription {
  padding-bottom: 24px;
}

.subtotalLineItems {
  margin-bottom: 12px;
}

.estimateSummaryHeader {
  padding-top: 24px;
  font-size: 18px;
  font-weight: $fontWeightSemi;
}

.item-label-card {
  cursor: pointer;
  border: solid 1px $border-color;
  border-radius: 6px;
  padding: 16px;
  margin-top: 12px;

  &:hover {
    border-color: $altColor 
  }
}

.labelsSection {
  padding-bottom: 32px;
}

.labelsTitleArea {
  padding-bottom:4px;
}

.userDecline, .trashIcon  {
  &:hover {
    fill: $altColor;
  }
}

.ant-modal-footer {
  padding: 24px;
}

.payment-item-card {
  border-radius: 16px;
  cursor: pointer;
  height: 15em;

  .small-text {
    font-size: 12px;
  }
  &:hover {
    border-color: $altColor !important;
    .payment-name {
      color: $altColor;
    }
    .credit-card-svg,.money-check-dollar-svg ,.google-pay-svg, .money-bill-svg, .apple-pay-svg{
      fill:$altColor !important;
    }
    
  }
}

.payment-drawer {
  .payment-summary {
    .each-row-large {
      padding-bottom: 20px;
    }
    .each-row {
      padding-bottom: 15px;
    }
  .partial-payment {
    padding-bottom: 20px;
  }
  }
  
}

.addEstimateName {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}

.secure-lock {
  fill: $secondaryText;
}


// Payment Drawer Updates

#paymentDrawer .ant-drawer-header {
  border-bottom: 0px;
}

.paymentDeviceSelect {
  height:100%;
  width:100%;
  border-radius:10px;
  &:hover {
    border: 1px solid $primaryColor;
  }
  .ant-card-body {
    padding: 16px 24px;
  }
  .deviceImagePayment{
    height: 75px;
    width:auto;
  }

  .deviceImagePaymentCol{
  text-align: center;
  }
  
}