@import "../../customstyle.scss";

.notes-modal {
  
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-header {
    border: none;
    padding-bottom: 0;
    border-radius: 16px;
  }

  .ant-modal-footer {
    border: none;
    border-radius: 16px;
    padding: 16px 24px;
    .ant-btn {
      min-width:49%;
    }

  }

  .cancel-button {
    border: none;
  }

  .time-range {
    margin-bottom: 24px;
  }
}