@import "../../customstyle.scss";

.print-label-drawer {
  .ant-drawer-header {
    border-bottom: none;
  }

  .ant-drawer-footer {
    border-top: none;
  }
}
