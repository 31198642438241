@import "../../customstyle.scss";

#settings-tabs {

    .ant-tabs-nav {
        padding: 12px 24px 0px 24px;
    }

    .ant-tabs-tab {
      padding: 12px 20px 20px 20px;
    }
    .each-row-item {
        padding-left: 24px;
    
    }
    .ant-upload-drag-container svg {    
        height: 65px;
    }
    .ant-upload-text {
        font-size: 14px;
    }
    .ant-upload-hint {
        font-size: 13px;
        color: $secondaryText;
    }
    .ant-upload.ant-upload-drag {
        padding: 12px 42px !important;
        background: #ffffff;
        margin-bottom: 16px;
        width: 100%;
    }
    .uploadText {
        padding-top: 12px;
        margin-bottom: -30px;
    }
    .time-range {
        margin-bottom:16px;
        vertical-align: middle;
    }
}

.settingsTitles {
    font-weight: $fontWeightMedium;
    margin-bottom: 24px;
    
}

.settingsDescriptions {
    color: $secondaryText;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px;
}

.integrate-connected {
    font-size: 16px;
    font-weight: 500;
    color:$successColor;
    margin-right: 12px;
}

.paymentUsrInfo {

    font-size: 13px;
}

.settingsPage .ant-tabs-content-holder {
    height: calc(100vh - 270px);
    overflow-y: scroll;
}

.settingsClinicLogo {
    max-width: 300px;
    min-width: 150px;
    max-height: 200px;
}

.addPayDeviceButton {
    height:145px;
    width:100%;
    max-width: 675px;
    font-size:16px;
    background-color:$secondaryBackgroundColor;
    border-radius: 12px;
    border-width: 2px;
    color: $secondaryText;
    &:hover {
        background-color:$secondaryBackgroundColor;
    }
}

.regDevicesButton {
    cursor: pointer;
    height: 100%;
    border-radius: 16px;
    width: 100%;
    border: 1px solid $borderColor;
    padding: 0.5em;
    max-width: 675px;
    &:hover {
        border:1px solid $primaryColor;
    }

}

.deviceImageCol {
    padding: 0px 12px 0px 0px;
    min-width: 100px;
    text-align: center;

}

.deviceImage {
    height:100px;
    width:auto;
}
.deviceImageCheckout {
    height: 75px;
    width: auto;
}

.DeviceCont {
    padding: 8px 16px;
}

.deleteDevice {
    text-align: right;
}

#settings-tabs-panel-integrations {
    padding:18px 10px;

}