
  @media print { 
    * { overflow: visible !important; } 
     @page { margin: 20px;padding: 0;}
  
    estimate-preview { 
        visibility: visible !important ; 
        position: absolute;
    left: 0;
    top: 0;
     }

    .no-print-view { 
       visibility: hidden; 
       page-break-after: avoid;
    }
    html, body {
        height:100vh; 
        margin: 0 !important; 
        padding: 0 !important;
        overflow: hidden;
      } 
    
     
  
  }
  .no-print-view {
    top: 10px;
    right: 50px;
    position: fixed;
    border-radius: 50px !important;

    
  }