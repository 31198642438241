@import "../../customstyle.scss";

.staffPageMaster {
  .createNewBTN {
    display:none;
  }
}

.staff-profile-details-tabs .remindersWidget {
  box-shadow: none !important;
}
.staff-page {
  display: flex;
  flex-wrap: wrap;
  min-width:20%;
  
  .masterContentPadding { 
  height: calc(100vh - 150px);
   overflow: scroll;
  }

  .staff-page-widget {
    padding:24px;
    border-radius: 16px;
    border: 1px solid $borderColor;
    background-color: white;
    width:100%;
    min-width: 320px;
    @include cardShadow;

    &:hover {
      &.add-new-staff {
      border: 2px dashed $altColor;
      }
    }

    &.add-new-staff {
      text-align: center;
      padding: 150px 90px 150px 90px;
      border: 2px dashed $borderColor;
      box-shadow: none;
      cursor: pointer;

        .addStaffIcon {
          fill: $primaryColor;
        }
        .add-new-staff-text {
          color:$primaryColor;
          letter-spacing: 0.6px;
          font-weight: 500;
          font-size: 20px;
        }
        &:hover {
          .addStaffIcon{
            fill: $altColor;
          }
          .add-new-staff-text{
            color: $altColor;
          }
          .add-new-staff {
            border: 2px dashed $altColor;
          }
        }
    }

    .add-new-staff-container {
      margin-top: 20px;
    }

    .works {
      margin-top: 10px;
    }

    .working-days {
      margin-bottom: 35px;
      margin-top: 10px;
    }

    .staff-status {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    .days-circle {
      width: 37px;
      height: 37px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #666;
      margin-right: 5px;
    }

    .working-hours {
      margin-bottom: 10px;
    }

    .working-hours, .settings {
      button {
        color: $button-text-color;
        width: 100%;
        margin-right: 20px;
        margin-left: 20px;
      }
    }
  }
}

.staff-profile {

  .staff-profile-info {
    .page-header {
      background-color: $altBackgroundColor;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding: 32px 24px;
      border-bottom: 1px solid $borderColor;
    }

    .edit-profile {
      margin-top: 20px;

      span {
        padding: 0 50px 0 50px;
      }
    }
    .editProfileContainer {
      display: flex;
      justify-content: center;
    }

    .custom-image .behaviorAlertCont {
      position: absolute;
      left: 20px;
    }


    .staff-information {
      .staff-secondary-config, .staff-primary-config, .staff-profile-config {
        padding: 24px;
      }

      .behaviorAlertIcon {
        fill: $dangerColor;
        height: 18px;
        padding-right:6px;
      }

      // .staff-secondary-config, .staff-profile-config {
      //   border-bottom: 1px solid $borderColor;
      // }

      .title {
        margin-top: 12px;
      }

      .staff-more-info, .staff-more-info {
        margin-bottom: 12px;

        .staff-details, .staff-details {
          margin-top: 20px;

          .behavior-alerts .anticon-warning {
            color: $dangerColor;
            font-size: 16px;
            margin-right: 5px;
          }

          .email-color {
            color: $altColor;
          }

          .text-color {
            color: $primaryText;
          }

          .dangerColor {
            color: $dangerColor;
          }
        }
      }
    }
  }

  .staff-profile-details-tabs {

    .ant-tabs-nav {
        padding: 12px 24px 0px 24px;
    }

    .ant-tabs-tab {
      padding: 12px 20px 12px 20px;
    }

    .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs-content-holder {
      overflow-y: scroll;
    }

    .staff-profile-tabs {
      // margin: px 0px 20px 0px;
      .ant-tabs-content-holder {
        padding:24px;
      }  
    }

    .editIcon, .trashIcon , .viewIcon, .cancel-circle-icon, .save-disk-icon {
      fill: $primaryColor;
      &:hover {
        fill: $altColor;
      }
    }

      

  }

 

  .staff-profile-widget {
    border-radius: 16px;
    background: white;
    @include cardShadow;
    height: calc(100vh - 170px);
      
    .table-title-top {
      margin: 0px 0px 20px 0px !important;
      }
    .ant-tabs-nav-list {
      padding-bottom: 12px;
    }
  }
}

@media (max-width:1500px) { 
  .staff-profile {
    row-gap:0px !important;
  }
  .staff-profile-widget {
    height: auto !important; 
  }
  .staff-profile-details-tabs {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .staff-details {
    padding: 12px 0px;
  }
  .staff-profile-tabs {
    min-height: 1000px;
  }
  .iPadScroller {
    height: calc(100vh - 150px) !important;
    overflow:scroll;
  } 
}