@import "../../customstyle.scss";


.appointment-details {
  .ant-radio-button-wrapper {
    padding: 0 32px;
    background: transparent;
    font-size: 13px;
  }
  .appointment-details-form {

    .appointmentSectionTitle {
      list-style: none;
      padding-left: 0px;
      .appointmentSectionIcon {
        float: left;
        padding-right: 12px;
    }
  }

    .appointment-client-details {

      .appointment-client-allergies {
        padding: 7px 0 7px 0;

        .client-allergies-detail {
          border-top: 1px solid rgba(224, 224, 224, 1);
        }

      }
    }

    .form-fields {
      display: block;
      padding: 0 32px 32px 32px;

      .form-fields-row {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        padding-bottom: 12px;
      }
    }
    .page-header {
      background-color: $altBackgroundColor;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding: 32px 24px;
      border-bottom: 1px solid $borderColor;
    }
  }

  .appointment-details-info {
    border-radius: 16px;
    @include cardShadow;

    .soap-exam {
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
    }

    .aptHistorySoapDetails {
      padding: 0px 32px 32px 32px;
      border-bottom: 1px solid $border-color;
      margin-bottom: 16px;
    }

    .appointment-tabs {
      padding: 12px 28px 28px 28px;
    }

    .ant-tabs-tab {
      padding: 12px 20px 24px 20px;
    }

    .ant-tabs-nav-operations {
      display: none;
    }

    .tab-history {
      .ant-collapse-content {
        background: $secondaryBackgroundColor;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      .ant-collapse-header {
        background: $secondaryBackgroundColor;
        height: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-collapse {
        background-color: white;
      }

      .ant-collapse-item {
        margin-bottom: 12px;
        border-radius: 8px;
        overflow: hidden;
      }

      .ant-collapse-content-box {
        padding-top: 0;
      }

      .tab-header {
        .doctor-name {
          padding-right: 25px;
        }

        .ant-collapse-extra {
          margin-left: auto;
          color: $altColor;
          margin-right: 40px;
        }
      }

      .history-list {
        position: relative;
      
      }
      .aptHistoryInLabels {
        padding-bottom:12px;
      }

      .status-text-align {
        float: right;
      }
    }

    .tab-health {
      #canvas_1 {
        width: 100% !important;
      }

      .lab-trending {
        margin-top: 20px;
      }
    }

    .tab-estimates {
      .ant-table-selection-column {
        .ant-checkbox-wrapper {
          display: none;
        }
      }

      .ant-table-row-level-1 {
        .actions {
          display: none;
        }
      }

      .customer-decline {
        padding-right: 12px;
      }

      .sub-total {
        display: flex;
        justify-content: flex-end;

        .total-amount {
          padding-left: 23px;
        }
      }

      .appointment-estimate-search {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 25px;
        border-radius: 50px;

        .ant-input {
          margin-right: 10px;
        }

        .ant-input-search-icon:before {
          border-left: none;
        }
      }

      .invoice {
        margin-top: 25px;

        .sub-total-amount {
          margin-top: 15px;
        }

        .invoice-summary {
          padding-right: 23px;
        }

        .total-due {
          padding: 56px 60px 56px 60px;
          border-radius: 6px;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .pay-now {
            margin-bottom: 15px;
          }

          button {
            width: 295px;
          }
        }
      }

    }

    .ant-table-cell {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

  }

  .main-icon {
    .anticon {
      margin-right: 10px;

      svg {
        width: $icon-size;
        height: $icon-size;
      }
    }
  }
  .appointment-details-widget {
    border-radius: 16px;
    background: white;
    
    overflow-y: scroll;
    @include cardShadow;

  }
}

.soapHistoryDate {
  font-size: 14px;
  @include fontSemi;
}

.appointmentBtmSave {
  min-width: 200px;
  float:right;

}

//Fixing the issue with the dynamic controller adding too much padding with no way to control it -- This is the Weight Unit Field on appointment details//
#appointment-form-id > div:nth-child(1) > div.ant-row.form-fields-row > div:nth-child(2) > div {
  padding-right: 20px !important;
}

.aptDetailsWeightLarge {
  font-size: 34px;
  font-weight: 600;
}

.aptWeightLBSSup {
  font-size: 12px;
}

@media only screen and (max-width: 1600px) and (min-width: 700px)  {
  .appointment-details-widget {
  .aptDetailsWeightLarge {
    font-size: 30px !important;
  }
  .aptWeightLBSSup {
    font-size: 12px !important;
  }
    .ant-col .with-image {
      width: 90px !important;
      height: 90px !important;
    }
    .aptPatientName  {
      font-size: 18px;
    }
    .appointment-client-allergies {
      padding:0px!important;
    }
    .ant-scroll-number-custom-component {
      top: -9px !important;
      left: -4px !important;
    }
  }
}

@media (max-width:1500px) { 
  .appointment-details {
    row-gap: 0px !important;
  }
  .iPadScroller {
    height: calc(100vh - 149px) !important;
    overflow:scroll !important
  }
  .appointment-details-info {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
  .appointment-details-widget {
    height:unset;
  }
  .appointment-details .appointment-details-widget {
    box-shadow: none !important;
  }
  .appointment-details .appointment-details-widget {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

}


