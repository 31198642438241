@import "../../../customstyle.scss";

.vital-drawer {
    .ant-drawer-header {
      border-bottom: none;
    }
  
    .ant-drawer-footer {
      border-top: none;
    }
    .ant-picker{
      width: 100%;
    }
  }

  #vital-tracker-table {
    .trashIcon, .editIcon {
      fill: $primaryColor;
      &:hover {
        fill: $altColor;
      }
    }

  }

