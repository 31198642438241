@import "../../customstyle.scss";

.print-label-drawer {
  .ant-drawer-header {
    border-bottom: none;
  }

  .ant-drawer-footer {
    border-top: none;
  }
}
.remindersItem {
  padding:24px;
  border-bottom:1px solid $border-color;
  &:hover {
      background: $hoverColor;

  }
}
.remindersCompletedItem {
  padding:24px;
  border-bottom:1px solid $border-color;
  opacity: 0.5;
  &:hover {
      background: $hoverColor;

  }
}
.reminderTitle {
  font-size: 14px;
  font-weight: 500;
}
.reminderBreed {
  font-size: 13px;
}

.reminder-modal {
  
  .appointment-modal-confirmation {
    border-right: 1px solid $borderColor;
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .reminder-modal-left {
    background-color: $altBackgroundColor;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom: 1px solid $borderColor;
    width: 100%;
    padding: 24px;
    border-right: 1px solid $borderColor;

    .ant-space-item:not(:first-child) {
      margin-bottom: 0 !important;
    }

    .appointment-details-name {
      font-size: 20px;
    }
     .client-initial {
       font-size: 18px !important;
     }
  }

  .ant-select-selection-item {
   
    text-transform: uppercase;
    font-size: 12px;
  }

  .wellness-text {
    background-color: $altBackgroundColor;
    border-radius: 20px;
    text-transform: uppercase;
    color: #ebf9f8;
    border-color: $altColor;
    position: relative;
    top: 25px;
  }

  .appointment-notes {
    padding: 36px 24px;
  }

  .appointment-owner-details {
    padding: 24px;

    .client-more-info {
      margin-bottom: 12px;
    }
  }
}

.reminderDatePicker {
  .ant-picker {
    width:100%;
  }

}
