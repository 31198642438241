@import "../../customstyle.scss";

#reports-page {
    .editIcon, .downloadIcon  {
      fill: $primaryColor;
      &:hover {
        fill: $altColor;
      }
    }
  }

  .reports-drawer {
    .ant-drawer-header {
      border-bottom: none;
    }

    .custom-image .circle-border {
      box-shadow: 0 0 0 3px $primaryColor;
    }
}
#reports-page {
    .custom-select {
      border-radius: 50px;
      background-color: white;
      color: black;
      border: 1px solid #d9d9d9; /* Optional: border color */
    }

    .custom-select .ant-select-selector {
      background-color: white;
      border-radius: 50px;
      background-color: white;
      color: black;
      border: 1px solid rgb(217, 217, 217); /* Make sure the dropdown matches */
    }
    
    .cardNumber .ant-statistic {
        font-size: 36px;
        font-weight: bold;
        color: #11153F; /* Dark text color for main value */
    }
      
    
    .cardNumber .growth-text {
        font-weight: bold;
        padding: 5px 12px;
        border-radius: 12px;
        display: inline-flex;
        align-items: center;
    }
    .cardNumber {
      .growth-text {
        color: #fff; /* Green color for text */
      }
      .cardParent{
        padding:10px;
        margin-top: 10px;
        border-radius:10px;
      }
      }

    .cardOne {
    .growth-text {
      background-color: #44BF9C;
    }
    .cardParent{
      background: transparent linear-gradient(120deg, #FFFFFF 0%, #b3efde75 100%) 0% 0% no-repeat padding-box;
    }
    }

    .cardTwo{
      .growth-text {
        background-color: #2A7E81;
      }
      .cardParent{
        background: transparent linear-gradient(120deg, #FFFFFF 0%, #caf2f3ad 100%) 0% 0% no-repeat padding-box;
      }
    }
    .cardThree{
      .growth-text {
        background-color: #2D42BE;
      }
      .cardParent{
        background: transparent linear-gradient(120deg, #FFFFFF 0%, #d4d8f382 100%) 0% 0% no-repeat padding-box;
      }
    }
    .cardFour{
      .growth-text {
        background-color: #CE3349;
      }
      .cardParent{
        background: transparent linear-gradient(120deg, #FFFFFF 0%, #ffc4cca7 100%) 0% 0% no-repeat padding-box;
      }
    }
  
    .cardNumber .stat-period {
        font-size: 14px;
        color: #11153F; /* Light gray for "in this month" */
        margin-left: 5px;
        margin-top: 5px;
        display: block;
        text-align: center;
    }
  
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.dotSettings {
  border-radius: 15px;
  padding: 10px;
}
.dotSettings{
    label.ant-radio-wrapper {
      margin: 3px 0px;
    }
    span.ant-radio {
      margin-right: 3px;
    }
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.mainSettings {
  border-radius: 15px;
  padding: 20px;
}
.mainSettings{
    label.ant-radio-wrapper {
      margin: 5px 0px;
    }
    span.ant-radio {
      margin-right: 10px;
    }
  }

  .dash-card .cardTitle:hover{
    text-decoration: underline;
  }
  #reports-page {
  .dash-card {
    height: 300px; /* Fixed height for all cards */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures proper spacing for header and content */
    overflow: hidden;
  }
  .graph-container {
    flex-grow: 1; /* Makes the graph container take up the remaining space */
    width: 100%;
    height: 100%;
  }
}

#reports-page {
  .overviewParent::-webkit-scrollbar, .cardParent::-webkit-scrollbar {
  height: 8px;
}

.overviewParent::-webkit-scrollbar-thumb ,.cardParent::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 4px;
}

.overviewParent::-webkit-scrollbar-thumb:hover, .cardParent::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}
}
#reports-page {
  .overviewParent{
    overflow-x: auto!important;
    white-space: nowrap;
  }
}