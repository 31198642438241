
  @media print { 
    @page { margin: 0;padding: 0;}
  
    .print-view { 
        visibility: visible !important ; 
        position: absolute;
    left: 0;
    top: 0;
     }
    .no-print-view { 
       visibility: hidden; 
       page-break-after: avoid;
    }
    html, body {
        height:100vh; 
        margin: 0 !important; 
        padding: 0 !important;
        overflow: hidden;
      } 
      .vetSig {
        font-weight: 400;
        font-size: 30px;
        font-family: "WindSong", cursive;
        min-height: 100px;
      }
    
     
  } 
  #print-lable-preview-card {

    .prev-b1{
      font-weight: 800;
      font-size: 20px;
  }
  
  }