@import "./../../customstyle.scss";

.site-layout .site-layout-background {
  background: #fff;
}

#landing-page {
  height: 100%;
  
  .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-menu-vertical {
    .ant-menu-submenu-title {
      padding: 0 2px !important;
      margin: 0;
    }
  }

  .ant-menu-submenu {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .ant-menu-submenu-vertical {
    &:hover {
      background-color: $secondaryBackgroundColor;
    }
  }
  
}

.site-layout .site-layout-background {
  background: #fff;
}


.utilityIcons {
  font-size: 20px !important;
  color: rgba(255, 255, 255, 0.65);
  background-color: transparent;
  margin: 0px 4px 0px 0px;
  cursor: pointer;
  -webkit-transition: color 0.15s linear;
  -ms-transition: color 0.15s linear;
  transition: color 0.15s linear;
}

.utilityIcons:hover,
.utilityIcons:active {
  color: #ffffff !important;
}

.menu-item-text{
  font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #008489;
    text-align: center;
}

#landing-page-menu.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0px solid #d9d9d9;
}

#landing-page-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
   background-color: #ffffff;
  
}

#landing-page-menu .ant-menu-item {
  height: auto;
  line-height: 1em;
  padding: 8px 2px;
  margin-bottom:0px;
  margin-top:0px;
 
}

.sideNavText {
  font-size: 12px;
  line-height: 22px;
  font-weight:500;
}

.ant-menu-item:hover {
  color: $primaryText;
  background-color: $hoverColor;
}

.ant-menu-vertical .ant-menu-item-only-child {
  margin-top: 0;
}

.ant-menu-submenu .ant-menu-vertical .ant-menu-item{
  margin-bottom: 0px !important;
}
