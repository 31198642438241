@import "../../customstyle.scss";

.appName {
  font-size: 18px;
  color: #fff;
  height: 64px;
}


.profileIcon {
  margin-left: 8px;
  font-size: 20px !important;
  cursor: pointer;
}

.userProfileCont .custom-image {
  box-shadow: 0 0 0 2px $primaryColor;
  border-radius: 50px;
  padding: 3px;
  cursor: pointer;
}

.userProfile {
  background-color: #ffffff;
  padding: 24px;
  border-bottom: 1px solid #f0f0f0;
}

.avatarProfile {
  width: 56px;
  height: 56px;
  font-size: 20px;
  color: #1e1e1e;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}


.userName {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.userRole {
  font-size: 12px;
  font-weight: 400;
  display: block;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.main-header-search {
  padding: 12px 0 12px 0;
  display:flex;
  .headerSearchBox {
    max-width: 578px;
    svg {
      width: 20px;
      height: 20px;
    }
    .ant-input-affix-wrapper {
      border-radius: 50px !important;
      border: none;
      padding-left:16px;
    }
    .headerSearchIcon {
      color: $secondaryText;
      padding-right:12px;
    }
    .ant-input-group-addon {
      display:none;
    }
    .ant-input-lg {
      min-height:auto;
    }

    .searchBreedText {
      font-size: 12px !important;
      color: $secondaryText !important;
    }

  }
}
.main-header-right {
  display: flex;
  justify-content: flex-end;
  .message, .bell {
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: auto;
      height: 28px;
      &:hover {
        cursor: pointer;
        fill: $altColor;
      }
    }
  }
  .userProfileCont {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .custom-image .without-image .client-initial {
    font-size: 16px !important;
  }

}

.profile-dropdown-menu {
      width: 18em;
      border-radius:1em;
      cursor: pointer;

  li {
    padding:1em;
    display: flex;
  }
  svg {
    width: 18px;
    margin-right:12px;
  }
  .profile-name {
    padding:20px 16px 8px;
  }
  .profileDDName {
    font-size: 16px;
    font-weight: 500;
  }

  .secure-lock {
    fill: $primaryColor !important;
    height: 20px !important;
  }


}

.clock-dropdown-menu {
  width: 22em;
  border-radius:1em;

li {
padding:1em;
display: flex;
}
svg {
width: 18px;
margin-right:4px;
}
.profile-name {
padding:24px;
}
.profileDDName {
font-size: 16px;
font-weight: 500;
}


}