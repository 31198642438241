@import "./../../../customstyle.scss";
.lot-details-drawer {
    .ant-drawer-header {
      border-bottom: none;
    }
    .trashIcon, .editIcon {
      fill: $primaryColor;
      &:hover {
        fill: $altColor;
      }
    }
  
    .labsDrawerName {
      font-size: 16px;
      font-weight: $fontWeightMedium;
  
    }
  
  
    #new-labs-table.ant-table-empty, #lab-drawer-table.ant-table-empty{
      background-color: #fafafa;
      pointer-events: none;
      tbody .ant-table-cell {
        padding: 5em !important;
  
      }
  }
  
  
  
    .custom-image .circle-border {
      border: 3px solid $primaryColor;
    }

    .circleborder {
      box-shadow: 0 0 0 3px $primaryColor;
      border-radius: 100%;
      display: inline-block;
      padding: 4px;
    }
  
    .AddLotIconCont {
      width:60px;
      height: 60px;
      background-color: $primaryColor;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  
       .addLotIcon {
         fill: #ffffff;
         height: 32px;
         
       }
    }
    .AddLotVMC {
      font-size:13px;
      line-height: 13px;
      color: $secondaryText;
    }
    .LotItemTitle {
      font-size:16px;
      font-weight:500;
  
    }
  }