
.App {
  height:100vh;
  width:100%;
}

// .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background: #008489;
// }



