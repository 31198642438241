@import "./../../customstyle.scss";
.inventoryStats {
    margin-bottom: 24px;
    .inventoryStatsItems {
        font-size: 13px;

    }
}

#inventoryDetailOverivew{
    .inventoryListTable {
        max-height: calc(100vh - 500px);
      }

      .pageCardContainer {
        border-radius: 1em;
        height: calc(100vh - 270px);
    
    
        .ant-card-body {
            padding: 0;
            height: calc(100vh - 322px);
        }
    }
    .trashIcon, .editIcon {
        fill: $primaryColor;
        &:hover {
          fill: $altColor;
        }
    }
    .ant-table-pagination.ant-pagination {
        margin: 12px 24px;
    }
}

.dash-card {
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 5px rgb(0 0 0 / 5%);
  }
    border-radius: 1em;

    .ant-card-body {
        padding:12px 20px 12px 20px;
    }

    .dash-icon {
        padding:1em 1em 0.5em 1em;
        background-color:var(--bc);
        border-radius:1em
    }
    .dash-count {
        font-weight:600;
        font-size:24px;
        line-height:32px;
        color:var(--bc)
    }
    .dash-row {line-height:0}
}

.dash-card.dash-card-selected {
    border-bottom:0.25em var(--bc) solid;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;

}

