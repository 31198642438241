.page-header-menu{
    width: 100%;
    
}

.createNewBTN {
    min-width: 200px;

    .plusIcon  {
        fill: #ffffff;
        padding-right: 8px;
        height: 13px;
    }
}

.ant-page-header-heading-title {
    font-weight: 500;
    font-size: 20px;
}