
.reg-form{
    width: 100%;

}

.reg-label{
    font-size: 18px;
}

.reg-desc{
    font-size: 16px;
   
    font-weight: 700;
    padding: 5px 0px;
}

.reg-btn{
    height: 50px !important;
}
.col-layout{
    border: 1px solid #eceaea;
    background-color: #fff;
    margin:'2em';
    
}
.reg-layout{
  margin: 0px;
  
}