@import "./../../customstyle.scss";

.labCardContainer {
  border-radius: 1em;
}

.lab-list-card {

    .ant-card-body {
        padding: 0;
    }
}

#lab-page {
    .trashIcon, .viewIcon, .sendToCloud  {
      fill: $primaryColor;
      &:hover {
        fill: $altColor;
      }
    }
    .ant-table-pagination.ant-pagination {
      margin: 12px 24px;
    }
  }

  #lab-history-table {
    .viewIcon {
        fill: $primaryColor;
        &:hover {
          fill: $altColor;
        }
      }
  }

  #new-labs-table, #lab-drawer-table {
    .viewIcon,.trashIcon,.new-labs-icon, .sendToCloud {
        fill: $primaryColor;
        &:hover {
          fill: $altColor;
        }
      }
  }

#new-labs-table.ant-table-empty{
    background-color: #fafafa;
    pointer-events: none;
    tbody .ant-table-cell {
      padding: 5em !important;

    }
}
  
.lab-drawer {
  .ant-drawer-header {
    border-bottom: none;
  }

  .labsDrawerName {
    font-size: 16px;
    font-weight: $fontWeightMedium;

  }


  #new-labs-table.ant-table-empty, #lab-drawer-table.ant-table-empty{
    background-color: #fafafa;
    pointer-events: none;
    tbody .ant-table-cell {
      padding: 5em !important;

    }
}
}

.tab-labs {
  .ant-table-tbody > tr > td {
    padding: 12px 16px !important;
  }
}

#lab-history-table #new-labs-table #lab-drawer-table  {
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    padding: 12px 16px;
  }
  .is-external-lab {
    background-color:#FEF6ED;
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: red !important;
    }
    .external-lab-column{
      border-left:#F59A23 solid 0.3em ;
    }
    .ant-table-cell-fix-right {
        background-color:inherit;
    }
  }
}

 #new-labs-table #lab-drawer-table  {
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    padding: 12px 16px;
  }
  .is-external-lab {
    background-color:#FEF6ED;
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: red !important;
    }
    .external-lab-column{
      border-left:#F59A23 solid 0.3em ;
    }
    .ant-table-cell-fix-right {
        background-color:inherit;
    }
  }
}




.ant-table-tbody > tr.ant-table-row:hover.is-external-lab > td {
  background: #ffeacc !important;
}
