@import "../../../customstyle.scss";

.custom-time-range {
  border: 1px solid $border-color;
  background-color: $hoverColor !important;
  .ant-select-arrow {
    display: none;
  }
  .ant-select-selection-placeholder {
    padding: 0;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0px !important;
}
}