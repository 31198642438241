@import "../../../customstyle.scss";

.client-edit-profile {
  .ant-drawer-header {
    border-bottom: none;
  }

  .ant-drawer-footer {
    border-top: none;
  }

  .patientDOBField {
    width: 100%;
  }
}