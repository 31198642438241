@import "../../customstyle.scss";

.UAWidget, .remindersWidget, .apptOverviewWidget, .inventoryAlertsWidget {
@include cardShadow
}

.UAWidget .ant-card-body  {
    padding:0px;
    overflow-y: scroll;
    max-height: 646px;
}

.UAWidget {
    .ant-card-extra {
        padding:0px !important;
    }
    .UACheckIcon { 
        display:none;
    }

    .UAcompletedState {
        .custom-image, .UAName, .UANotes, .UATimePanel {
            opacity: 0.5;
        }
        .UACheckIcon {
            display:flex;
            margin-top: -31px;
            z-index: 2;
            background: $successColor;
            border-radius: 50px;
            box-shadow: 0 0 0 3px #ffffff;
            margin-left: 55px;
            height: 30px;
            .completeApptCheck {
                width:30px;
                height:30px;
                fill:#d4f1d4;
            }
        }
    }

    .UACard {
        padding: 12px 24px;
        margin-bottom: none;
        cursor: pointer;
        &:hover {
            background-color: $hoverColor;
        }
    }

    ///.UAPhoto {
    //}

    .UAPhotoCont {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .UADetailsPanel {
        padding: 0px 0px 0px 16px;
    }

    .UAapptConfirmation {
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        font-weight: $fontWeightSemi;
    }

    .UAName {
        font-size: 14px;
        font-weight: 500;
    }

    .UANotes {
        font-size: 13px;
        font-weight: 300;
    }

    .UATagBase {
        border-radius: 20px;
    }

    .UATimePanel {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .UATime {
        float: right;
        font-size: 14px;
        font-weight:500;
        display: inline-flex;
    }

    .UATagBase {
        margin-right: 0px;
        text-transform: uppercase;
        font-weight:500;
        text-align: center;
        min-width: 140px;
        padding: 6px 10px;
        font-size: 12px;
        margin-top: 6px;

    }


.wellnessUATag {
   
    background-color: #EBFEFF;
    color: #008489;  
    border: 1px solid #008489;
  }
  
  .dentalUATag {
   
    background-color: #e1e3fe;
    color: #9966FF;  
    border: 1px solid #9966FF;
  }
  
  .euthanasiaUATag {
   
    background-color: #f4dedf;
    color: #E83151;  
    border: 1px solid #E83151;
  }

  .groomingUATag {
   
    background-color: #ADF0FF;
    color: #00B7E0;  
    border: 1px solid #00B7E0;
  }
  
  .examUATag {
    
    background-color: #cff4df;
    color: #43A047;  
    border: 1px solid #43A047;
  }
  
  .emergencyUATag {
    
    background-color: #FFF8E1;
    color: #FF8F00;  
    border: 1px solid #FF8F00;
  }

  .blockUATag {

    background-color: #D7D7D7;
    color: #717171;  
    border: 1px solid #717171;
  }

  .othersUATag {

    background-color: #D7D7D7;
    color: #717171;  
    border: 1px solid #717171;
  }

  .boardingUATag {
      
    background-color: #C8D4EA;
    color: #2F4D86;  
    border: 1px solid #2F4D86;
  }

  .surgeryUATag {
    
    background-color: #cde8f8;
    color: #0483DD;  
    border: 1px solid #0483DD;
  }

  .bathUATag {
    
    background-color: #EBFEFF;
    color: #008489;  
    border: 1px solid #008489;
  }

  .earcleaningUATag {
    
    background-color: #FFF8E1;
    color: #FF8F00;  
    border: 1px solid #FF8F00;
  }

  .nailtrimUATag {
    
    background-color: #C8D4EA;
    color: #2F4D86;  
    border: 1px solid #2F4D86;
  }

  .haircutUATag {
    
    background-color: #cde8f8;
    color: #0483DD;  
    border: 1px solid #0483DD;
  }

  .blowoutUATag {
    
    background-color: #cff4df;
    color: #43A047;  
    border: 1px solid #43A047;
  }

  .teethbrushingUATag {

    background-color: #D7D7D7;
    color: #717171;  
    border: 1px solid #717171;
  }

  .brushUATag {

    background-color: #D7D7D7;
    color: #717171;  
    border: 1px solid #717171;
  }
}

.inventoryAlertsWidget {
    .ant-card-body {
        padding:0px;
    }
    .ant-table.ant-table-middle {
        border-bottom: 1px solid $border-color;
    }
}

.remindersWidget {
    .ant-card-body {
        padding: 0px;
        overflow: scroll;
        max-height: 645px;
    }
    .remindersItem {
        padding:24px;
        border-bottom:1px solid $border-color;
        &:hover {
            background: $hoverColor;

        }
    }
    .remindersCompletedItem {
        padding:24px;
        border-bottom:1px solid $border-color;
        opacity: 0.5;
        &:hover {
            background: $hoverColor;

        }
    }
    .reminderCloseIcon {
        &:hover {
           color:$altColor; 
        }
    }
    .ant-avatar {
        background:$primaryColor;
    }

    .ant-avatar-group .ant-avatar {
        border:none;
        box-shadow: 0px 0px 0px 2px #ffffff;
    }
    .full-check {
        .ant-checkbox-inner {
            width: 30px;
            height: 30px;
            border-radius: 50px !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(45deg) scale(1.3) translate(-50%, -50%);
        }
        .ant-checkbox-inner::after {
            top: 54%;
            left: 33%;
        }
        .ant-checkbox-checked::after {
            border-radius: 50px;
        }
    }
    
    .half-check {
        .ant-checkbox-checked .ant-checkbox-inner {
            background: #F1F5FA;
            border: 1px solid $border-color;
        }
        .ant-checkbox-inner {
            width: 30px;
            height: 30px;
            border-radius: 50px !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
            border-color: #606969;
            transform: rotate(45deg) scale(1.3) translate(-50%, -50%);
            display:none;
        }
        .ant-checkbox-inner::after {
            top: 51%;
            left: 37%;
        }
        .ant-checkbox-checked::after {
            border-radius: 50px;
        }
    }
    

    .ant-divider-horizontal {
        margin: 18px 0;
    }
    .remindersTitle {
        font-size: 14px;
        font-weight: 500;
    }
    .remindersDetails {
        font-size: 13px;
        font-weight: 300;
    }
}

.apptOverviewWidget {
    .ant-card-body {
        display:flex;
        justify-content: center;
    }
    .AOchartsAlign {
        max-width: 500px;
        align-content: center;

    }
    

    .recharts-legend-item-text{
        //font-family: 'Open Sans', sans-serif;
        color:$primaryText !important;
        font-size: 13px;
    }
    .recharts-legend-item {
        margin-bottom: 6px;
    }
    .recharts-surface {
        margin-right: 8px !important;
    }

    .recharts-legend-wrapper {
        right: 40px;
    }
}


.empty-text {
    color:$emptyText;
    font-size: 16px;
    font-weight: 500;
}

.inventoryAlertsWidget {
    .buyIcon, .editIcon {
      fill: $primaryColor;
      &:hover {
        fill: $altColor;
      }
    }
  }

  .lotExpirationWidget {
    .buyIcon, .editIcon {
      fill: $primaryColor;
      &:hover {
        fill: $altColor;
      }
    }
    .questionIcon {
        color: $primaryColor;
        &:hover {
            color: $altColor;
        }
    }
    .lotExpirationDate {
        font-weight:500;
    }
  }

