.onboard-page {

  #onboard-form-id {
    .ant-form-item-label > label
{
  width: 100%}
  }
    .welcome-name {
      font-size: 28px;
      margin-bottom: 12px;
    }
    .begin-setup-btn {
      width:100%;
      border-radius: 18px;
    }
  }